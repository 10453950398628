import getCaptchaToken from "./getCaptchaToken";
export default function () {
  document.addEventListener("submit", async (e) => {
    e.preventDefault();
    const form = e.target;
    if (!form[0].checkValidity()) return;
    const formResponse = form.querySelector(".form-response");
    if (formResponse) formResponse.remove();
    const submitter = e.submitter;
    submitter.disabled = true;
    const formData = new FormData(form);
    const subject = form.subject.value;

    if (subject == "calculating") {
      const projectName = document.querySelector("h1").textContent;
      const projectLink = document.location;
      formData.append("project-name", projectName);
      formData.append("project-link", projectLink);
    }

    const token = await getCaptchaToken();
    formData.append("g-recaptcha-token", token);

    const response = await fetch("/api/mail.php", {
      method: "POST",
      body: formData,
    });
    const { message, errors } = await response.json();

    const responseEl = document.createElement("div");
    responseEl.classList.add("form-response");
    responseEl.classList.add(
      errors ? "form-response--error" : "form-response--success",
    );
    responseEl.textContent = message;
    form.append(responseEl);

    if (!errors) {
      setTimeout(() => {
        responseEl.remove();
        Array.from(form.elements).forEach((element) => {
          if (element.type == "hidden") return;
          element.value = "";
        });
      }, 5000);
    }
    submitter.disabled = false;
  });
}
