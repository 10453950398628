// import * as Popper from "@popperjs/core"
import { fslightbox } from "fslightbox";
import { Offcanvas, Modal } from "bootstrap";
import Swiper, { Navigation, Pagination } from "swiper";
// import Swiper and modules styles
import "/node_modules/swiper/swiper.scss";
import "/node_modules/swiper/modules/navigation/navigation.scss";
import "/node_modules/swiper/modules/pagination/pagination.scss";
import formsInit from "./forms.js";

document.addEventListener("DOMContentLoaded", () => {
  formsInit();
});
const project_slider = new Swiper(".project-slider", {
  modules: [Navigation, Pagination],
  speed: 400,
  slidesPerView: 1,
  loop: true,
  navigation: {
    nextEl: ".project-slider .swiper-button-next",
    prevEl: ".project-slider .swiper-button-prev",
  },
  pagination: {
    el: ".project-slider .swiper-pagination",
    type: "bullets",
    clickable: true,
  },
});
const pf_slider = new Swiper(".fasades-slider", {
  speed: 400,
  slidesPerView: 1,
  spaceBetween: 40,
  breakpoints: {
    480: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
    },
    992: {
      slidesPerView: 4,
    },
    1200: {
      slidesPerView: 5,
    },
  },
  modules: [Navigation],
  navigation: {
    nextEl: ".fasades-slider .swiper-button-next",
    prevEl: ".fasades-slider .swiper-button-prev",
  },
});
// const bsOffcanvas = new Offcanvas('#headerNav')

// document.addEventListener('click', (e) => {
// console.log(e)
// if(e.target.closest('.header__nav a')){
//     setTimeout(()=>{
//         bsOffcanvas.toggle()
//     }, 800)
// }
// })

// document.addEventListener('show.bs.modal', (e) => {
//     const subject = e.relatedTarget.dataset.subject
//     const form = e.delegateTarget.querySelector('form')
//     const projectFileLayout = `
//     <div class="mb-3">
//         <label for="formFile" class="form-label">Файл проекта (jpg, png, pdf, zip)</label>
//         <input class="form-control" type="file" name="projectFile">
//     </div>
//     `
//     const textAreaLayout = `

//     `

//     if (e.delegateTarget.id == 'orderModal') {
//         e.delegateTarget.querySelector('.modal-title').textContent = subject
//     }
//     form.subject.value = subject

//     if (subject == 'Рассчитать мой проект' && form.projectFile == undefined) {
//         console.log(form.projectFile)
//         form.email.closest('div').insertAdjacentHTML('afterend', projectFileLayout)
//     }else if (subject !== 'Рассчитать мой проект' && form.projectFile ){
//         form.querySelector('input[name=projectFile]').closest('div').remove()
//     }

//     if (subject == 'Заказать звонок') {
//         form.querySelector('input[name=email]').closest('div').style.display = 'none'
//     }else {
//         form.querySelector('input[name=email]').closest('div').style.display = 'block'
//     }

// })

// document.addEventListener('shown.bs.modal', (e) => {
//     const frameId = e.target.querySelector('iframe').id
//     amo_forms_params.resizeForm(frameId)
// const frameSrc = e.target.querySelector('iframe').src
// const url = new URL(frameSrc)
// const formId = JSON.parse(url.hash.substring(1).replaceAll('%22', '"')).formId

// window.amo_forms_loaded(function (params){
//     console.log(params)
// }, formId)
// })

